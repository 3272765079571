import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from '../Menu'


import './style.scss'
import { useContext } from 'react'
import Context from '../../themeContext'
const Header = () => {

  const [header, setHeader] = useState(false)
  
  const {theme} = useContext(Context)
  const logo = theme === "Light" ? "" : "Dark"

  const changeBackground = () => {
    if(window.scrollY >= 10){
      setHeader(true)
    }else{
      setHeader(false)
    }
  }

  window.addEventListener('scroll', changeBackground)

  return (
    <div className={header ? 'header active' : 'header'}>
      <Link to='/'>
       <img src={process.env.PUBLIC_URL + `/assets/LogoHorizontal${logo}.svg`} alt="logo" />
      </Link>
      <Menu />            
    </div>
  )
}

export default Header
